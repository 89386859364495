import { useLoaderData } from "@remix-run/react";
import { useEffect } from "react";
import { Container, Flex, Heading, Text } from "sparta";
import AddUsers from "~/routes/$platform._index/components/AddUsers";
import Explore from "~/routes/$platform._index/components/Explore";
import FeaturedHero from "~/routes/$platform._index/components/FeaturedHero";
import { IndexLoader } from "~/routes/$platform._index/types";
import PostExporter from "~/utils/export/PostExporter";
import { useStateBanner } from "~/utils/hooks/StateBanner";

export default function Home() {
  const loaderData = useLoaderData<IndexLoader>() as IndexLoader;
  useStateBanner(loaderData);

  useEffect(() => {
    const { url } = loaderData;

    const postExporter = new PostExporter(url);
    postExporter?.exportIfApplicable();
  }, [loaderData]);

  return (
    <Container p="2">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="0">
          <Heading as="h1" size="7">
            Home
          </Heading>
          <Text>
              Stay ahead of the curve with real-time updates on the latest GovCon developments.
          </Text>
        </Flex>
        <AddUsers loaderData={loaderData} />
        <FeaturedHero />
        <Explore />
      </Flex>
    </Container>
  );
}
